import * as React from 'react'
import { useState, useRef, useEffect } from 'react'
import { useIsVisible } from 'react-is-visible'
import {
  Box,
  Heading,
  Flex,
  Container, Image,
} from '@chakra-ui/react'
import Circle from './Circle';
const StaticalData = React.forwardRef(({ data }) => {
  const ref = useRef();
  const isVisible = useIsVisible(ref)
  const [value, setValue] = useState(0)

  useEffect(() => {
    isVisible && setTimeout(() => setValue(70), 500) 
  }, [isVisible])

  return (
    <Box
      pt="5rem"
      pb="8rem"
      bg="#FBFBFB"
      mb="6.25rem">
      <Container
        ref={ref}
        maxW="7xl">
        <Heading 
          mb="12"
          fontSize="2xl" 
          as="h3"
          textAlign="center"
          >
          {data.statisticTitle}
        </Heading>
        <Flex
          direction={{base: 'column', md: 'row'}}
          alignItems={{base: 'center', md: 'flex-start'}}
          justifyContent="space-between">
          {data.statisticalData?.map((item, idx) => (
            <Circle
              key={`circle-${item.title}-${idx}`}
              title={item.title}
              text={item.text}
              value={value}
              mainColor={item.mainColor}
              secondaryColor={item.secondaryColor}>
              <Image
                  objectFit="cover"
                  src={item.icon}
              />
            </Circle>
          ))}
        </Flex>
      </Container>
    </Box>
  );
});

export default StaticalData
