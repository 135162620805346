import React from 'react'
import {
  CircularProgress,
  Flex,
  Box,
} from '@chakra-ui/react'

const Circle = ({children, value, title, text, mainColor, secondaryColor}) => {
  return (
    <Box maxW="270px">
      <Flex
        h="134px"
        mb="7"
        alignItems="center"
        justifyContent="center">
          <Box
            zIndex="2"
            position="absolute">
            <CircularProgress sx={{
              '& circle:first-child': {
                stroke: secondaryColor,
              }
            }}
            fill="black"
            value={value}
            size='134px'
            color={mainColor}
            thickness='15px'/>
          </Box>
          <Box position="absolute">
            {children}
          </Box>
      </Flex>
      <Box
        display="block"
        textAlign="center"
        mb="4"
        fontSize="26px"
        fontWeight="500"
        as="span">
        {title}
      </Box>
      <Box
        display="block"
        textAlign="center"
        mb="4"
        fontSize="18px"
        fontWeight="700"
        as="span">
        {text}
      </Box>
    </Box>
  )
}

export default Circle
