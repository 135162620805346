import * as React from "react";
import { Container, Heading, Image, Box } from "@chakra-ui/react";
import SmallNav from "../../components/smallNav";
import { graphql } from "gatsby";
import Main from "../../components/layouts/Main";
import Seo from "../../components/SEO";
import { useTranslation } from "gatsby-plugin-react-i18next";
import WhatWeDo from "../../components/whatToExpect/WhatWeDo";
import StaticalData from "../../components/whatToExpect/StaticalData";
import CommentsPanel from "../../components/whatToExpect/CommentsPanel";
import SubmitClaim from "../../components/whatToExpect/SubmitClaim";
import Template from "../../components/whatToExpect/Template";
import ExamComplaintsComp from "../../components/submitClaim/ExamComplaintsComp";
import usePrismicWhatToExpect from "../../hooks/usePrismicWhatToExpect";
import usePrismicRepeatSubmitClaim from "../../hooks/usePrismicRepeatSubmitClaim";
const isBrowser = () => typeof window !== "undefined";
const DesputesSolution = () => {
  const { language } = useTranslation();
  const submitaclaim = usePrismicRepeatSubmitClaim();
  const data = usePrismicWhatToExpect();
  const section = isBrowser() && window.location.hash.replace("#", "");
  const scrollToRef = (offsetTop) => window.scrollTo(0, offsetTop);
  const offsetTop = (ref) => ref.current.offsetTop - 150;
  const independentRef = React.useRef(null);
  const impartialRef = React.useRef(null);
  const fastRef = React.useRef(null);
  const productiveRef = React.useRef(null);
  const professionalRef = React.useRef(null);
  const findOutRef = React.useRef(null);
  const submitClaimRef = React.useRef(null);

  React.useEffect(() => {
    if (section === "independent") {
      scrollToRef(offsetTop(independentRef));
    }
    if (section === "impartial") {
      scrollToRef(offsetTop(impartialRef));
    }
    if (section === "fast") {
      scrollToRef(offsetTop(fastRef));
    }
    if (section === "productive") {
      scrollToRef(offsetTop(productiveRef));
    }
    if (section === "professional") {
      scrollToRef(offsetTop(professionalRef));
    }
    if (section === "find-out") {
      scrollToRef(offsetTop(findOutRef));
    }
    if (section === "submit-a-claim") {
      scrollToRef(offsetTop(submitClaimRef));
    }
  }, [section]);
  return (
    <Main>
      <Container mb="5" px="5" maxW="7xl">
        <Seo
          title={data.header}
          description={data.header}
          link={`https://www.fsm.am/${language}/Ի՞նչ ակնկալել`}
        />
        <SmallNav>{data.header}</SmallNav>
        <Heading
          as="h1"
          fontSize={{ base: "2xl", sm: "3xl" }}
          fontWeight="900"
          mt={{ base: "3", sm: "5" }}
        >
          {data.header}
        </Heading>
        <WhatWeDo data={data} />
        <Template
          reverse={true}
          ref={independentRef}
          title={data.regardless.title}
          image={data.regardless.image}
          description={data.regardless.description}
        />
        <Template
          reverse={false}
          ref={professionalRef}
          title={data.professional.title}
          image={data.professional.image}
          description={data.professional.description}
        />

        <Template
          reverse={true}
          ref={impartialRef}
          title={data.fair.title}
          image={data.fair.image}
          description={data.fair.description}
        />
        <Template
          reverse={false}
          ref={fastRef}
          title={data.fast.title}
          image={data.fast.image}
          description={data.fast.description}
        />
        <Template
          reverse={true}
          ref={productiveRef}
          title={data.effective.title}
          image={data.effective.image}
          description={data.effective.description}
        />
        <StaticalData data={data} ref={productiveRef} />
        
        <CommentsPanel data={data} />
      </Container>
      <ExamComplaintsComp
        scrollPosition={findOutRef}
        ref={findOutRef}
        scrollTo={scrollToRef}
      />
      <SubmitClaim ref={submitClaimRef} submitClaim={submitaclaim} />
    </Main>
  );
};

export default DesputesSolution;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
