import * as React from "react";
import { Box, Text, Stack } from "@chakra-ui/react";
import RichText from "../PrismicRichText";

const WhatWeDo = ({ data }) => {
  return (
    <Stack
      mt={{base: '1.875rem', sm: "3.75rem"}}
      justifyContent="space-between"
      spacing="10"
      direction={{ base: "column", md: "row" }}
    >
      <Box >
        <Box fontSize="sm">
          <RichText text={data.description}/>
        </Box>
      </Box>
    </Stack>
  );
};
export default WhatWeDo;
